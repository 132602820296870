/* ========================================================================
 * OFFCANVAS NAVIGATION
 * ======================================================================== */
(function($) {

  var siteWrapper = '#site-wrapper';
  var toggleButton = '#toggle-offcanvas-lang';
  var overlay = '.offcanvas-overlay';

   // Set Viewport height using drawer element
   function setViewportHeight() {
    var windowHeight = parseInt($(window).height());
    $(siteWrapper).height(windowHeight);
  }

  // Set viewport height to auto
  function setAutoHeight() {
    $(siteWrapper).height('auto');
  }

  // Offcanvas navigation
  function toggleNav(button) {

    var toggleClass = $(button).data('toggle-class');
    if ($(siteWrapper).hasClass(toggleClass)) {
      // Do things on Nav Close
      $(siteWrapper).removeClass(toggleClass);
      setAutoHeight();
    } else {
      // Do things on Nav Open
      $(siteWrapper).addClass(toggleClass);
      setViewportHeight();
    }
  }

  // Toggle Offcanvas Button
  function toggleButtonNav(button) {
    $(button).trigger('click');
  }

  $(document).ready(function() {
    // Toggle offcanvas navigation
    $(toggleButton).click(function() {
      // Calling a function in case you want to expand upon this.
      toggleNav(this);
    });

    // Toggle overlay
    $(overlay).click(function() {
      toggleButtonNav(toggleButton);
    });

  });

  // Hide on ESC KeyPress
  $(document).keyup(function(e) {
    if (e.keyCode === 27 && $(siteWrapper).hasClass('show-nav-lang')) {
      toggleButtonNav(toggleButton);
    }
  });
})(jQuery); // Fully reference jQuery after this point.
