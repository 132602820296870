/* ========================================================================
 * BLULAB SCRIPTS
 * ======================================================================== */
(function ($) {

  $('.single.single-vini ul li.menu-item-vini').addClass('current-menu-item');
  $('.single.single-vigneti ul li.menu-item-vigneti').addClass('current-menu-item');
  $('.page.parent-pageid-244 ul li.menu-item-download').addClass('current-menu-item');

  scrollToElement = function (el, ms) {
      var speed = (ms) ? ms : 3400;
      $('html,body').animate({
          scrollTop: $(el).offset().top
      }, speed);
  };

  function isotope_settings() {
    // Isotope
    $('.isotope-list').isotope({
      itemSelector: '.grid-item',
    });
  }
  $(document).ready(function () {

    $('.toggleicon').click(function () {
      $('.toggleicon').toggleClass('active');
    });

    var $grid = $('.isotope-list').isotope({
      itemSelector: '.grid-item',
    });

    // Isotope
    isotope_settings();

    $('.scrollbottom').click(function (e) {
        // prevent default action
        e.preventDefault();
        scrollToElement($(this).attr('href'), 1000);
    });

    // Match Height by Row
    $('.equal-el').matchHeight({
      byRow: true,
      property: 'height',
      target: null,
      remove: false
    });

    // Match Height
    $('.equal-all-el').matchHeight({
      byRow: false,
      property: 'height',
      target: null,
      remove: false
    });

    $('.equal-el-container').each(function() {
      $(this).children('.equal-el-item').matchHeight({
        target: $(this).find('.equal-el-altezzafissa'),
      });
    });

     // filter items on button click
     $('.filter-item').on('click', function (e) {
      e.preventDefault();
      $('.filter-item').removeClass('current-menu-item');
      $(this).toggleClass('current-menu-item');
      var filterValue = $(this).data('filter');
      $grid.isotope({filter: filterValue});
  });

    // SLIDER
    $('.default-slider .slides').on('init', function(slick) {
    $('.default-slider .slides').removeClass('cS-hidden');

    }).slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: true,
      speed: 400,
      fade: true,
      cssEase: 'linear',
      arrows: false,
      mobileFirst: true,
      autoplay: true,
      autoplaySpeed: 4500,
      pauseOnFocus: false,
      pauseOnHover: false
    });

    $(".page-template-template-home #myModal").modal('show');

  });


  $(window).load(function () { // inizio load

    // Isotope
    isotope_settings();

  }); // fine load

  $(window).resize(function () { // inizio resize

    // Isotope
    isotope_settings();

  }); // fine resize


})(jQuery); // Fully reference jQuery after this point.
